<template>
  <div>
    <div class="air__utils__heading"> <h5>Inspección Visual</h5> </div>
    <my-breadcrumbs :routes="routes"/>
    <visual-inspection-page :typeForm="'controlMaterialInspectionVisual'" :authorizeCheck="'Inspección Visual'"></visual-inspection-page>
  </div>
</template>

<script>
import VisualInspectionPage from '@/views/geocycle/laboratories/visual_inspection/visualInspectionPage.vue'
export default {
  name: 'visualInspectionMain',
  components: {
    VisualInspectionPage,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorio',
        },
        {
          breadcrumbName: 'Inspección Visual',
          aIcon: '',
        },
      ],
    }
  },
}
</script>
