<template>
  <div>

    <a-table
      :dataSource="tableObject.data"
      :columns="columns"
      :loading="tableObject.loading"
      :row-key="record => record.id"
      :pagination="tableObject.pagination"
      @change="refreshResults"
      bordered
      :scroll="{ x: 1300 }">
      <template slot="expandedRowRender" slot-scope="record">
          <small>
            <strong>
              {{ `Comentarios: ` + ' ' }}
            </strong>
            <strong>
              {{ (record.receiptSamples.notes) ? record.receiptSamples.notes : '-' }}
            </strong>
          </small>
      </template>
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
                <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
                <template v-else>{{fragment}}</template>
            </template>
          </span>
        <template v-else>{{text}}</template>
      </template>
      <template slot="code" slot-scope="record">
        <strong>
          {{ record.receiptSamples.code }}
        </strong>
      </template>
      <template slot="serviceDetail" slot-scope="record">
        <small>
          <strong>
            Centro de Trabajo:
          </strong>
        </small>
        <small>
          {{ (record.receiptSamples.workCenters != null) ? record.receiptSamples.workCenters.code : '' }} - {{ (record.receiptSamples.workCenters != null) ? record.receiptSamples.workCenters.name : '' }}
        </small>
        <br/>
        <small>
          <strong>
            Cliente/Solicitante:
          </strong>
        </small>
        <small>
          {{ record.receiptSamples.lab_customer }}
        </small>
        <hr class="bg-primary">
        <small>
          <strong>
            No. de Servicio:
          </strong>
        </small>
        <small>
          {{ record.receiptSamples.invoice_code }}
        </small>
        <br/>
        <small>
          <strong>
            Corriente:
          </strong>
        </small>
        <small>
          {{ record.receiptSamples.currents.name }}
        </small>
        <br/>
        <small>
          <strong>
            Material:
          </strong>
        </small>
        <small>
          {{ record.receiptSamples.sample_material }}
        </small>
        <br/>
        <small>
          <strong>
            Tipo de Contenedor:
          </strong>
        </small>
        <small>
          {{ record.receiptSamples.containerTypes.name }}
        </small>
      </template>
      <template slot="inspectionDetail" slot-scope="record">
        <small><strong>Fecha de Inspección:</strong></small>
        <small>
          {{moment.utc(record.inspected_at).format('YYYY-MM-DD hh:mm a')}}
        </small>
        <hr class="bg-primary">
        <small><strong>Responsable de Validación:</strong></small>
        <small>
          {{ (usersCombo) ? ((usersCombo.find(x => x.id == record.user_id)) ? usersCombo.find(x => x.id == record.user_id).name : '-') : 'No cargó' }}
        </small>
        <br/>
        <small><strong>Turno:</strong></small>
        <small>
          {{ (shiftsCombo) ? ((shiftsCombo.find(x => x.id == record.shift_id)) ? shiftsCombo.find(x => x.id == record.shift_id).name : '-') : 'No cargó' }}
        </small>
      </template>
      <template slot="sampleDetail" slot-scope="record">
        <small>
          <strong>
            Tipo de Ingreso:
          </strong>
        </small>
        <small>
          {{ record.receiptSamples.sample_type }}
        </small>
        <br/>
        <small>
          <strong>
            Cantidad de Muestra Tomada:
          </strong>
        </small>
        <small>
          {{record.receiptSamples.quantity}}
        </small>
        <hr class="bg-primary">
        <small>
          <strong>
            Fecha de recepción:
          </strong>
        </small>
        <small>
          {{ moment.utc(record.received_at).format('YYYY-MM-DD hh:mm a')}}
        </small>
      </template>
      <template slot="disposal" slot-scope="record">
        {{record.name}}
      </template>
      <template slot="container_quantity" slot-scope="record">
        {{ (record.manifestData != null) ? ((record.manifestData.container_quantity != null) ? record.manifestData.container_quantity : '-') : '-' }}
      </template>
      <template slot="container_type_id" slot-scope="record">
        {{ (record.manifestData != null) ? ((record.manifestData.container_types != null) ? record.manifestData.container_types.name : '-') : '-' }}
      </template>
      <template slot="quantity" slot-scope="record">
        {{ (record.manifestData != null) ? record.manifestData.quantity : '-' }}
      </template>
      <template slot="u_m_s_id" slot-scope="record">
        {{ (record.manifestData != null) ? record.manifestData.ums.sap_code : ''}} - {{ (record.manifestData != null) ? record.manifestData.ums.name : '' }}
      </template>
      <template slot="container_quantity_real" slot-scope="record">
        {{ (record.realData != null) ? ((record.realData.container_quantity != null) ? record.realData.container_quantity : '-') : '-' }}
      </template>
      <template slot="container_type_id_real" slot-scope="record">
        {{ (record.realData != null) ? ((record.realData.container_types != null) ? record.realData.container_types.name : '-') : '-' }}
      </template>
      <template slot="diff_quantity" slot-scope="record">
        {{ (record.manifestData != null && record.manifestData != null) ? ((record.manifestData.container_quantity != null && record.realData.container_quantity != null) ? (record.manifestData.container_quantity - record.realData.container_quantity) : '-') : '-' }}
      </template>
      <template slot="incidents" slot-scope="record">
        {{ (record.dischargeMaterialIncidents != null) ? record.dischargeMaterialIncidents.code : ''}} - {{ (record.dischargeMaterialIncidents != null) ? record.name : '' }}
      </template>
      <template slot="visualInspections" slot-scope="record">
        <div v-if="record.visualInspections">
          <small v-if="record.visualInspections.shifts"><strong>Turno:</strong></small>
          <small v-if="record.visualInspections.shifts">{{ record.visualInspections.shifts.name }}</small>
          <hr class="bg-primary">
          <small v-if="record.visualInspections.shifts"><strong>Recibido:</strong></small>
          <small v-if="record.visualInspections.shifts">{{ moment(record.visualInspections.received_at).utc().format('DD/MM/YY HH:mm') }}</small>
          <hr class="bg-primary">
          <small v-if="record.visualInspections.shifts"><strong>Inspeccionado:</strong></small>
          <small v-if="record.visualInspections.shifts">{{ moment(record.visualInspections.inspected_at).utc().format('DD/MM/YY HH:mm') }}</small>
          <hr class="bg-primary">
          <small v-if="record.visualInspections.users"><strong>Validador:</strong></small>
          <small v-if="record.visualInspections.users">{{ record.visualInspections.users.name }}</small>
          <hr class="bg-primary">
          <small v-if="record.visualInspections">
            <a-icon v-if="record.status" type="check-circle" theme="twoTone" two-tone-color="#52c41a" aria-label="Concluido" />
            <a-icon v-else type="clock-circle" theme="twoTone" two-tone-color="#ed2022" aria-label="Pendiente"/>
          </small>
        </div>
      </template>
      <!-- Columnas Personalizadas -->
      <template slot="actions" slot-scope="record">
        <!-- <responsive-button responsive="sm"
                           icon="file-earmark"
                           variant="primary" pill size="sm"
                           title=""
                           text=""
                           @ClickEvent="clickButton('','discharge_service_plannings')"
        />
        <br/> -->
        <b-button-group size="sm">
          <b-button class="mr-1"  variant="warning" @click="OpenModal(record)" pill size="sm">
            <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/>
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Editar">
              <b-icon icon="pencil-square"/>
            </div>
          </b-button>
        </b-button-group>
        <template>
          <hr class="bg-primary">
          <span class="small font-weight-bold">Estatus de Inspección:</span>
          <span class="small font-weight-bold text-primary" v-if="record.status === 1 || record.status === '1' || record.status === true || record.status === 'true'">
            Concluido
          </span>
          <span v-else class="small font-weight-bold text-warning">
            Pendiente
          </span>
          <hr class="bg-primary">
        </template>
      </template>
    </a-table>

    <!-- MODAL -->
    <b-modal title="Inspección Visual" ref="modal-visualIns" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--FORMULARIO-->
        <a-form-model :model="resourceObj.form" :rules="rules" ref="ruleForm" enctype="multipart/form-data"
                      layout="vertical" :wrapper-col="{ span: 24}" v-show="!spinner">
          <!--SELECT -Responsable de la validación -->
          <a-form-model-item label="Responsable de la validación" ref="users" prop="users" >
            <a-select v-model="resourceObj.form.users"
                      placeholder="Responsable de la validación"
                      show-search
                      :disabled="disabledObject"
                      :filter-option="filterOption"
            >
              <a-select-option  v-for="option in usersCombo" :key="option.id" :value="option.id.toString()" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Responsable de la validación -->
          <!--SELECT -Turno -->
          <a-form-model-item label="Turno" ref="shifts" prop="shifts" >
            <a-select v-model="resourceObj.form.shifts"
                      placeholder="Turno"
                      show-search
                      :disabled="disabledObject"
                      :filter-option="filterOption">
              <a-select-option  v-for="option in shiftsCombo" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Turno -->
          <!--SELECT -Estatus -->
          <a-form-model-item label="Estatus" ref="status" prop="status" >
            <a-select v-model="resourceObj.form.status"
                      placeholder="Estatus"
                      show-search
                      :disabled="disabledObject"
                      :filter-option="filterOption">
              <a-select-option  v-for="option in statusCombo" :key="option.value" :value="option.value" >
                {{option.label}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Estatus -->
          <a-row>
            <!--DATEPICKER - Fecha de Recepción-->
            <a-col :md="{ span: 12 }">
              <a-form-model-item  label="Fecha de Recepción" ref="inspected_at" prop="inspected_at" >
                <a-date-picker  v-model="resourceObj.form.received_at"
                                placeholder="Fecha de Recepción"
                                disabled
                                format="DD/MM/YYYY HH:mm"
                                size="large"
                                valueFormat="YYYY/MM/DD HH:mm"
                                style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <!--DATEPICKER - Fecha de Recepción-->
            <!--DATEPICKER - Fecha de inspección-->
            <a-col :md="{ span: 12 }">
              <a-form-model-item  label="Fecha de inspección" ref="inspected_at" prop="inspected_at" >
                <a-date-picker  v-model="resourceObj.form.inspected_at"
                                placeholder="Fecha de inspección"
                                showTime
                                format="DD/MM/YYYY HH:mm" size="large"
                                valueFormat="YYYY/MM/DD HH:mm"
                                :disabled="disabledObject"
                                style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <!--DATEPICKER - Fecha de inspección -->
          </a-row>
          <!--TEXT-AREA - Observaciones-->
          <a-form-model-item label="Observaciones" ref="text"  prop="text" >
            <a-textarea v-model.trim="resourceObj.form.text"
                        :auto-size="{ minRows: 4 }"
                        :disabled="disabledObject"
            />
          </a-form-model-item>
          <!--TEXT-AREA -Observaciones -->
          <!--Dropzone - arrayPhotos-->
          <a-form-model-item v-if="resourceObj.form.id"  label="Fotos" ref="arrayPhotos" prop="arrayPhotos">
            <div class="clearfix">
              <a-upload accept="image/*,.pdf" name="file"
                        :data="{'id': resourceObj.form.id, strategy:'VisualInspections'}"
                        :action="attachFileUrl"
                        list-type="picture-card"
                        :file-list="resourceObj.form.arrayPhotos"
                        :before-upload="BeforeUpload"
                        @preview="handlePreview"
                        @change="UploadPhotos"
                        :remove="RemovePhoto"
              >
                <div>
                  <b-icon icon="plus"/>
                  <div class="ant-upload-text">Cargar Evidencia</div>
                </div>
              </a-upload>
              <!-- MODAL para vista previa de imagen -->
              <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false" :zIndex="3000">
                <img alt="previewFile" style="width: 100%;" :src="previewImage" />
              </a-modal>
              <!-- MODAL para vista previa de imagen -->
            </div>
          </a-form-model-item>
          <fadeAlert text="La imagen cargada es ,mayor a 2MB" variant="danger" v-model="fadeAlter"/>
          <!--Dropzone - arrayPhotos-->
          <hr class="bg-primary">
          <div class="row" v-show="!disabledObject">
            <div class="col-md-4 offset-md-4 col-12" style="margin-bottom:13px">
              <b-button variant="primary" @click="Update" pill class="btn-block">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
              </b-button>
            </div>
          </div>
          <div class="row mt'2">
            <div class="col-md-4 offset-md-4 col-12">
              <b-button variant="warning" pill class="btn-block" :to="{ name: 'visualInspectionPrint', params: {id: resourceObj.form.id}}">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Imprimir">
                  <b-icon icon="printer"/> Imprimir
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Imprimir">
                  <b-icon icon="printer"/>
                </div>
              </b-button>
            </div>
          </div>
        </a-form-model>
        <my-spinner :load="spinner"/>
        <!--FORMULARIO-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->

  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { fractalMixin } from '@/mixins/fractalMixin'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'visualInspectionTable',
  mixins: [fractalMixin],
  props: {
    tableObject: Object,
    typeForm: {
      default: 'inspectionDischarge',
      type: String,
    },
    authorizeCheck: {
      default: 'Sin Autorización',
      type: String,
    },
  },
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
      // Columnas de la tabla
      columns: [
        {
          title: 'Código',
          dataIndex: '',
          key: 'code',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'code',
          },
        },
        {
          title: 'Detalle del Servicio',
          dataIndex: '',
          key: 'serviceDetail',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'serviceDetail',
          },
        },
        {
          title: 'Datos de la muestra',
          dataIndex: '',
          key: 'sampleDetail',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'sampleDetail',
          },
        },
        {
          title: 'Detalle de la inspección',
          dataIndex: '',
          key: 'inspectionDetail',
          // key: 'characteristics',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'inspectionDetail',
            // customRender: 'dangerous',
          },
        },
        {
          title: 'Acciones',
          dataIndex: '',
          class: 'small text-center',
          scopedSlots: { customRender: 'actions' },
        },
      ],
      // ************************************************************************************************************
      // Formulario del recurso
      disabledObject: false,
      resourceObj: {
        resourceType: 'visualInspections',
        form: {
          id: '',
          users: undefined, // Responsable de validacion
          shifts: undefined, // turno
          status: 0, // Estatus
          received_at: '',
          inspected_at: '',
          text: '',
          arrayPhotos: [],
        },
        relationships: ['users', 'shifts'],
      },
      rules: {
        users: [{
          required: true,
          message: 'El campo Responsable de la Validación es obligatorio.',
          trigger: 'blur',
        }],
        shifts: [{
          required: true,
          message: 'El campo Turno es obligatorio.',
          trigger: 'blur',
        }],
        status: [{
          required: true,
          message: 'El campo Estatus es obligatorio.',
          trigger: 'blur',
        }],
        received_at: [{
          required: true,
          message: 'El campo Fecha de Recepción es obligatorio.',
          trigger: 'change',
        }],
        inspected_at: [{
          required: true,
          message: 'El campo Fecha de Inspección es obligatorio.',
          trigger: 'change',
        }],
        text: [{
          required: true,
          message: 'El campo Observaciones es obligatorio.',
          trigger: 'blur',
        }],
        // arrayPhotos: [{
        //   required: true,
        //   message: 'La evidencia Fotográfica es obligatoria.',
        //   trigger: 'blur',
        // }],
      },
      // Combos de formulario
      usersCombo: [],
      shiftsCombo: [],
      statusCombo: [{ value: 0, label: 'Pendiente' }, { value: 1, label: 'Concluido' }],
      // Filtro s de busqueda
      filterWorkCenter: '',
      filterServicePlanning: '',
      // Combos de filtros
      servicesCombo: [],
      // Controles auxiliares y para el flujo del DOM
      attachFileUrl: `${process.env.VUE_APP_API_URL}/attachFile`,
      fadeAlter: false,
      spinner: false,
      previewVisible: false,
      previewImage: '',
    }
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_SERVICEPLANNINGID', 'GOTO_SERVICEPLANNINGID']),
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
    deleteRecord(data = {}) {
      this.$emit('DeleteRecord', data)
    },
    checkUpdate(checkValue, checkType, data = {}) {
      this.$emit('CheckUpdate', checkValue, checkType, data)
    },
    clickButton(serviceId, origin) {
      this.GOTO_SERVICEPLANNINGID({
        id: serviceId,
        name: origin,
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    validateDisableObject(statusValue) {
      this.disabledObject = statusValue === 1
    },
    async FetchCombos() {
      const params1 = {
        orderField: 'name',
        orderType: 'asc',
      }
      const arr = [
        {
          path: '/fetch/users',
          params: params1,
        },
      ]
      const [users] = await this.GetConcurrencyResources(arr)
      this.shiftsCombo = await this.GetResource('/shifts')
      this.usersCombo = users
      // const params = {
      //   'filter[visualInspections]': '',
      // }
      // this.servicesCombo = await this.GetResource('servicePlannings', params)
    },
    async OpenModal(data = {}) {
      this.$refs['modal-visualIns'].show()
      this.spinner = true
      this.resourceObj.form.id = data.id
      this.resourceObj.form.users = (data.user_id != null) ? (data.user_id.toString()) : undefined
      this.resourceObj.form.shifts = (data.shift_id != null) ? (data.shift_id) : undefined
      this.resourceObj.form.status = data.status ? 1 : 0
      // Call function to disable object or not
      this.validateDisableObject(this.resourceObj.form.status)
      this.resourceObj.form.received_at = data.received_at
      this.resourceObj.form.inspected_at = data.inspected_at
      this.resourceObj.form.text = data.text
      if (data.photos === null) {
        this.resourceObj.form.arrayPhotos = []
      } else this.resourceObj.form.arrayPhotos = data.photos
      // await this.FetchCombos()
      this.spinner = false
      console.log('Valores form:', this.resourceObj.form)
    },
    CloseModal() {
      this.$refs['modal-visualIns'].hide()
      this.resourceObj.form = {
        id: '',
        users: undefined, // Responsable de validacion
        shifts: undefined, // turno
        status: 0, // Estatus
        received_at: '',
        inspected_at: '',
        text: '',
        arrayPhotos: [],
      }
    },
    async UploadPhotos(info) {
      const { file } = info
      console.log('File:', file)
      if (Object.hasOwnProperty.call(file, 'response') && Object.hasOwnProperty.call(file.response, 'photos')) {
        this.resourceObj.form.arrayPhotos = file.response.photos
        // await this.getData()
        this.$emit('updateTable')
      }
    },
    BeforeUpload(file) {
      // Confirmacion de tamaño del archivo
      console.log('File:', file)
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.fadeAlter = true
        return false
      } else {
        this.resourceObj.form.arrayPhotos.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        })
        this.resourceObj.form.arrayPhotos.slice(-2)
        return true
      }
    },
    async RemovePhoto(file) {
      console.log('File:', file)
      const params = {
        id: this.resourceObj.form.id,
        url: file.url,
        strategy: 'VisualInspections',
      }
      await this.DeleteFile('/dettachFile', params, async(resp) => {
        this.resourceObj.form.arrayPhotos = resp.photos
        console.log('Remove photos:', resp)
        // await this.getData()
        this.$emit('updateTable')
      })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async Update() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          delete this.resourceObj.form.arrayPhotos
          await this.PutResource(this.resourceObj, async() => {
            this.spinner = false
            this.CloseModal()
            this.refreshResults()
          })
        } else return false
      })
    },
  },
  async mounted() {
    await this.FetchCombos()
  },
}
</script>

<style scoped>

</style>
